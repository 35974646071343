<template>
  <main class="step-page4">
    <Heading :title="'Lokalizacja'" />
    <section class="step4 step step--fourth">
      <div class="step4__container">
        <div class="step4__inner">
          <img class="step4__map" src="@/assets/images/map.svg" alt="map" data-aos="fade-in">
          <div class="step4__right">
            <p class="step4__header" data-aos="fade-in" data-aos-delay="250">
              Ponad <span>2.500</span><br>
              Partnerów <span>Dobroplast</span><br>
              <span>w całej Polsce</span>
            </p>
            <p data-aos="fade-in" data-aos-delay="500" class="step4__label">Twoja lokalizacja</p>
            <input :disabled="loading" @focus="clearInput()" id="searchTextField" data-aos="fade-in" data-aos-delay="500" type="text" class="step4__input" placeholder="Wpisz kod lub nazwę miasta">
          </div>
          <!-- / step4__right -->

        </div>
        <!-- / step4__inner -->

      </div>
      <!-- / step4__container -->

      <Bottom>
        <Button :path="'step3'" :title="'Wróć'" :classes="'e-button--secondary'" />
        <Button :loading="loading" :path="'step5'" :title="'Dalej'" :classes="btnClass" />
      </Bottom>
    </section>
    <!-- / step4  -->
    
  </main>
</template>

<script>
import Date from "@/components/Date.vue";
import Bottom from "@/components/Bottom.vue";
import Button from "@/components/Button.vue";
import Heading from "@/components/Heading.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "Step4",
  components: {
    Date,
    Bottom,
    Button,
    Heading, 
    Loader
  },
  mounted() {
    var input = document.getElementById('searchTextField');
    var options = {
      types: ['(regions)'],
      componentRestrictions: {country: 'pl'},
    };

    let autocomplete = new google.maps.places.Autocomplete(input, options);

    input.value = this.selectedLocalisation;

    let $this = this;

    google.maps.event.addListener(autocomplete, 'place_changed', function() {
      $this.updateLocalisation(autocomplete.getPlace());
    });

  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    updateLocalisation(value) {
      this.loading = true;

      this.$store.dispatch('updateLocalisation', value).then(() => {
        this.loading = false;
      });
    },
    clearInput() {
      let input = document.getElementById('searchTextField');

      if(input.value) {
        input.value = '';
  
        this.$store.commit('updateDistributor', { value: {} });
        this.$store.commit('updateLocalisation', { value: '' });
      }
    }
  },
  computed: {
    dates() {
      return this.$store.state.dates;
    },
    selectedLocalisation() {
      return this.$store.state.selectedLocalisation;
    },
    btnClass() {
      return this.selectedLocalisation ? 'e-button--primary' : 'e-button--disabled';
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.checkRoute(to, from)) {
      next();
    }

    if (! this.selectedLocalisation) {
      return;
    }

    next();
  }
};
</script>

<style scoped lang="scss">

  .step4 {

    &__container {
      margin-bottom: 30px;
      
      @media #{$xl} {
        // margin-bottom: 30px;
        margin-left: 140px;
      }
    }

    &__map {
      width: 150px;
      height: auto;

      @media #{$sm} {
        height: 161px;
      }
    }

    &__inner {
      @media (max-width: $max-md) {
        text-align: center;
      }
      @media #{$lg} {
        @include flex(flex-start, flex-start, nowrap);
      }
    }

    &__header {
      @extend %header;
      color: $color-text;

      span {
        color: $color-primary;
        font-weight: 700;
      }
    }

    &__right {
      padding-top: 15px;

      @media (max-width: $max-md) {
        text-align: left;
      }
      @media #{$lg} {
        padding-left: 28px;
      }
    }

    &__label {
      @extend %big-label;
      color: $color-primary;
      margin-top: 30px;
      margin-bottom: 14px;
    }

    &__input {
      @extend %product-name;
      width: 255px;
      height: 51px;
      border: 1px solid $color-primary;
      color: $color-text;
      max-width: 100%;
      padding: 0 20px;

      &::placeholder {
        color: $color-text;
      }
    }

    &__input-container {
      position: relative;
      max-width: 255px;
      
      img, svg {
        position: absolute;
        right: 8px;
        z-index: 1;
        top: 13px;
        width: 24px;
        height: 24px;
      }
    }
  }
</style>

